.map {
  height: 75vh;
  background: #eeeeee;
  border: 1px solid #979797;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  position: relative;
}

.noAreaContent {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.retryButton {
  align-self: center;
  margin-top: 30px;
  width: 100px;
}

.unsupportedAreaText {
  text-align: center;
}

.mapContainer {
  position: relative;
}
.mapOverlayButtons {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
